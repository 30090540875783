import { Controller } from '@hotwired/stimulus'
import { animateCSS } from '../src/helpers'

export default class extends Controller {
  static targets = ['toast']
  static values = {
    startAnimation: { type: Array, default: ['animate-in', 'slide-in-from-top'] },
    endAnimation: { type: Array, default: ['animate-out', 'fade-out'] },
    duration: { type: Number, default: 3000 }
  }

  toastTargetConnected (element) {
    animateCSS(element, this.startAnimationValue, () => {
      setTimeout(() => {
        animateCSS(element, this.endAnimationValue, () => {
          element.remove()
        })
      }, this.durationValue)
    })
  }
}
