import { Controller } from '@hotwired/stimulus'
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'

export default class extends Controller {
  static targets = ['editor', 'button', 'input']
  static values = {
    content: String,
    placeholder: String,
    classes: String
  }

  connect () {
    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false
        })
      ],
      editorProps: {
        attributes: {
          class: this.classesValue + ' tiptap'
        }
      },
      content: this.contentValue,
      onUpdate: ({ editor }) => {
        this.content = editor.getHTML()
        this.inputTarget.value = editor.getHTML()
      },
      onSelectionUpdate: () => {
        this.updatedAt = Date.now()
        this.#highlightButtons()
      }
    })
  }

  disconnect () {
    this.editor.destroy()
  }

  toggleItalic () {
    this.editor.chain().toggleItalic().focus().run()
  }

  toggleBold () {
    this.editor.chain().toggleBold().focus().run()
  }

  toggleBlockquote () {
    this.editor.chain().toggleBlockquote().focus().run()
  }

  toggleBulletList () {
    this.editor.chain().toggleBulletList().focus().run()
  }

  toggleOrderedList () {
    this.editor.chain().toggleOrderedList().focus().run()
  }

  setLink () {
    const previousUrl = this.editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
      return
    }

    // update link
    this.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
  }

  clearFormatting () {
    this.editor.chain().focus().clearNodes().unsetAllMarks().run()
  }

  reconnect () {
    this.editor.destroy()
    this.connect()
  }

  #highlightButtons () {
    const activeClasses = ['bg-gray-300', 'dark:bg-bunker-800']
    this.buttonTargets.forEach(button => {
      if (this.editor.isActive(button.title.toLowerCase())) {
        button.classList.add(...activeClasses)
      } else {
        button.classList.remove(...activeClasses)
      }
    })
  }
}
