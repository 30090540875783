import { AutoForm } from "@mguidetti/stimulus-components"

// The purpose of this controller is to handle HotwireCombobox hw-combobox:selection event which
// fires even if no option is selected. We should submit a PR to HotwireCombobox to fix this. 

export default class AutoFormCombobox extends AutoForm {
  submit(event) {
    if (event.detail.value === "") {
      return null
    }

    super.submit()
  }
}
