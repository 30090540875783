import { Controller } from '@hotwired/stimulus'
import autosize from 'autosize'

export default class extends Controller {
  connect () {
    autosize(this.element)
  }

  reconnect () {
    autosize.destroy(this.element)
    this.connect()
  }
}
