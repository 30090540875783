import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['menu']
  static values = {
    open: { type: Boolean, default: false }
  }

  connect () {
    useClickOutside(this)
  }

  clickOutside () {
    if (this.openValue) {
      this.hide()
    }
  }

  toggle () {
    this.openValue = !this.openValue
  }

  hide () {
    this.openValue = false
  }

  show () {
    this.openValue = true
  }

  openValueChanged () {
    if (this.openValue) {
      this.menuTarget.classList.remove('hidden')
      this.dispatch('shown')
    } else {
      this.menuTarget.classList.add('hidden')
      this.dispatch('shown')
    }
  }
}
