/* global localStorage */

import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['select']

  connect () {
    this.update()
    this.setSelect()
  }

  update () {
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  setSelect () {
    const value = localStorage.getItem('theme')

    if (value) {
      this.selectTarget.value = value
    }
  }
}
