import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/base'

export default class extends Controller {
  static values = {
    url: String,
    create: { type: Boolean, default: false },
    valueField: { type: String, default: 'name' },
    labelField: { type: String, default: 'name' },
    searchField: { type: String, default: 'name' }
  }

  connect () {
    this.boundSelect = this.bindElement(this.element)
  }

  reconnect () {
    this.boundSelect.destroy()
    this.connect()
  }

  bindElement (element) {
    const remoteSearchConfig = {
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.searchFieldValue,
      load: (query, callback) => {
        const url = new URL(this.urlValue)
        const params = new URLSearchParams(url.search.slice(1))
        const options = { headers: { Accept: 'application/json' } }
        params.append(this.searchFieldValue, query)
        url.search = params

        fetch(url, options)
          .then(response => response.json())
          .then(json => {
            callback(json)
          })
          .catch(() => {
            callback()
          })
      }
    }

    const config = {
      create: this.createValue,
      createFilter: function (input) {
        input = input.toLowerCase()
        return !(input in this.options)
      },
      ...(this.hasUrlValue && remoteSearchConfig)
    }

    const tomSelect = new TomSelect(element, config)

    return tomSelect
  }
}
