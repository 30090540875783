export function animateCSS (
  element: HTMLElement,
  animationName: string,
  callback: Function
): void {
  element.classList.add(...animationName)

  const handleAnimationEnd = () => {
    element.classList.remove(...animationName)
    element.removeEventListener('animationend', handleAnimationEnd)

    if (typeof callback === 'function') callback()
  }
  element.addEventListener('animationend', handleAnimationEnd)
}
