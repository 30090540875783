/* global localStorage */

import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static values = {
    key: String
  }

  set (event) {
    const value = event.target.value

    if (value) {
      localStorage.setItem(this.keyValue, value)
    } else {
      localStorage.removeItem(this.keyValue)
    }

    this.dispatch('set', { detail: { value } })
  }
}
