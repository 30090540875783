import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  uncheckOthers (event) {
    this.checkboxTargets.forEach(element => {
      if (element !== event.target) {
        element.checked = false
      }
    })

    this.dispatch('checked', { detail: { element: event.target } })
  }
}
