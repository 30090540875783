/* global Image */

import { Controller } from '@hotwired/stimulus'
import Uploader from '../src/uploader'
// import { setFlash } from '../src/flash'

const DEFAULT_LEAVING_MESSAGE = 'You have uploads in progress. Are you sure you want to exit this page?'

export default class extends Controller {
  static targets = ['progressContainer', 'progressTemplate']
  static values = {
    maxDimension: { type: Number, default: 3840 },
    maxBytes: { type: Number, default: 52428800 }, // 50mb
    safelist: { type: Array, default: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'] },
    fieldName: { type: String, default: '[file]' },
    uploading: { type: Boolean, default: false },
    leavingMessage: { type: String, default: DEFAULT_LEAVING_MESSAGE }
  }

  async handleNewFiles (event) {
    const files = Array.from(event.target.files)
    const approvedFiles = await Promise.all(files.map(file => this.validateFile(file)))
    const results = approvedFiles.filter(Boolean).map(f => f)

    event.target.value = null

    results.forEach(file => this.uploadFile(file))
  }

  inject (event) {
    const signedId = event.detail.previousEvent.detail.signedId

    if (!signedId) return false

    const element = event.detail.element
    const signedIdField = element.querySelector(
      `input[type="hidden"][name*="${this.fieldNameValue}"`)

    signedIdField.value = signedId

    const previewFilename = element.querySelectorAll('[data-attachments-target="preview-filename"]')
    const preview = element.querySelectorAll('[data-attachments-target="preview"]')
    const file = event.detail.previousEvent.detail.file

    previewFilename.forEach(previewFilename => { previewFilename.innerText = file.name })

    if (file.type === 'image/tiff') {
      preview.forEach(preview => {
        preview.insertAdjacentHTML(
          'afterend',
          `<div class="flex flex-col gap-y-1 items-center justify-center w-32 h-32 rounded bg-gray-300 dark:bg-bunker-800 opacity-50">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-10">
              <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
            <span class="text-xs font-bold">
              TIFF 
            </span>
          </div>`
        )
        preview.remove()
      })
    } else {
      preview.forEach(preview => { preview.src = URL.createObjectURL(file) })
    }
  }

  async validateFile (file) {
    if (!this.safelistValue.includes(file.type)) {
      console.warn('invalid file type')
      this.dispatch('invalid-type', { detail: { file } })
      // setFlash('alert', `Invalid file type: ${file.name}`)
      return false
    }

    if (file.size > this.maxBytesValue) {
      console.warn('file over size')
      this.dispatch('over-size', { detail: { file } })
      // setFlash('alert', `File size too large: ${file.name}`)
      return false
    }

    if (this.hasMaxDimValue) {
      const largestDimension = await this.getLargestDimension(file)

      if (largestDimension > this.maxDimensionValue) {
        console.warn('file over dimension')
        this.dispatch('over-dimension', { detail: { file } })
        // setFlash('alert', `Dimensions are too lage: ${file.name}`)
        return false
      }
    }

    return file
  }

  getLargestDimension (file) {
    return new Promise(resolve => {
      const img = new Image()
      img.src = URL.createObjectURL(file)

      img.onload = () => {
        const largestDimension = Math.max(img.width, img.height)
        URL.revokeObjectURL(img.src)
        resolve(largestDimension)
      }
    })
  }

  uploadFile (file) {
    const id = Date.now() * Math.floor(Math.random() * 100)
    const progressHtml = this.progressTemplateTarget.innerHTML
      .replace('UPLOAD_ID', id)
      .replace('FILENAME', file.name)

    this.progressContainerTarget.insertAdjacentHTML('beforeend', progressHtml)

    const uploader = new Uploader(file, { id, onChangeFile: this.handleFileProgress })

    this.uploadingValue = true
    uploader.start()
  }

  handleFileProgress = (event) => {
    const id = event.id
    const element = document.querySelector(`[data-upload-id="${id}"]`)
    const progressBar = element.querySelector('.progress-bar')
    const progressNumber = element.querySelector('.progress-number')

    element.dataset.uploadStatus = event.state

    switch (event.state) {
      case 'uploading':
        if (progressBar) { progressBar.style.transform = `scaleX(${event.progress / 100})` }
        if (progressNumber) { progressNumber.innerText = `${Math.trunc(event.progress)}%` }
        this.dispatch('upload-progress', { detail: { id, element, progress: event.progress } })
        break
      case 'error':
        // setFlash('alert', `Error uploading ${event.file.name}.`)
        this.dispatch('upload-error', { detail: { id, element, file: event.file } })
        break
      case 'finished': {
        const signedId = event.signedId
        const template = event.file.type.substring(0, event.file.type.lastIndexOf('/'))

        this.uploadingValue = false
        this.dispatch('upload-finished', { detail: { id, element, file: event.file, signedId, template } })
        break
      }
      default:
        break
    }
  }

  guardLeave (event) {
    if (this.uploadingValue) {
      if (event.type === 'turbo:before-visit') {
        if (!window.confirm(this.leavingMessageValue)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = this.leavingMessageValue
        return event.returnValue
      }
    }
  }
}
