import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['availableList', 'associatedList', 'position']
  static values = {
    animation: { type: Number, default: 150 }
  }

  initialize () {
    this.bindLists()
  }

  bindLists () {
    this.availableListTargets.forEach(element => {
      Sortable.create(element, {
        group: 'shared',
        animation: this.animationValue,
        sort: false,
        onAdd: event => {
          const destroyInput = event.item.querySelector(
            'input[name*="_destroy"]'
          )

          if (destroyInput) destroyInput.value = 1
        }
      })
    })

    Sortable.create(this.associatedListTarget, {
      group: 'shared',
      animation: this.animationValue,
      onAdd: event => {
        event.item.querySelectorAll('input[type="hidden"]').forEach(input => {
          input.disabled = false
        })
      },
      onSort: () => this.updatePostions()
    })
  }

  updatePostions () {
    this.positionTargets
      .filter(node => this.associatedListTarget.contains(node))
      .forEach((position, index) => (position.value = index))
  }
}
