/* global Turbo */

import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static values = {
    href: String
  }

  navigate () {
    Turbo.visit(this.hrefValue)
  }
}
