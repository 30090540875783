import { DirectUpload } from "@rails/activestorage"

export type UploaderOptionsType = {
  onChangeFile: (event: UploaderStateType) => void
  onBeforeBlobRequest?: any
  onBeforeStorageRequest?: any
  id: string
}

export type UploaderStateType =
  | { state: "waiting"; id: string; file: File }
  | { state: "uploading"; id: string; file: File; progress: number }
  | { state: "error"; id: string; file: File; error: string }
  | { state: "finished"; id: string; file: File; signedId: string }

class Uploader {
  directUpload: any
  options: UploaderOptionsType

  constructor(file: File, options: UploaderOptionsType) {
    this.directUpload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this)
    this.options = options
  }

  start() {
    const promise = new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob.signed_id)
        }
      })
    })

    return promise.then(this.handleSuccess).catch(this.handleError)
  }

  handleChange = (upload: UploaderStateType) => {
    this.options.onChangeFile(upload)
  }

  handleProgress = ({ loaded, total }) => {
    const progress = (loaded / total) * 100

    this.handleChange({
      state: "uploading",
      id: this.options.id,
      file: this.directUpload.file,
      progress
    })
  }

  handleSuccess = (signedId: string) => {
    this.handleChange({
      state: "finished",
      id: this.options.id,
      file: this.directUpload.file,
      signedId
    })
    return signedId
  }

  handleError = (error) => {
    this.handleChange({
      state: "error",
      id: this.options.id,
      file: this.directUpload.file,
      error
    })
    throw error
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    this.options.onBeforeBlobRequest &&
      this.options.onBeforeBlobRequest({
        id: this.options.id,
        file: this.directUpload.file,
        xhr
      })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.options.onBeforeStorageRequest &&
      this.options.onBeforeStorageRequest({
        id: this.options.id,
        file: this.directUpload.file,
        xhr
      })

    xhr.upload.addEventListener("progress", this.handleProgress)
  }
}

export default Uploader