import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ['content']
  static values = {
    content: String
  }

  connect () {
    const options = {
      allowHTML: true,
      interactive: true,
      theme: 'ry2g'
    }
    if (this.hasContentTarget) {
      // Note: innerHTML must be used instead of passing the element itself because
      // if an element it passed, tippy removes it from the DOM and turbo will cache
      // the page and the element will not be present on restoration visit connections
      options.content = this.contentTarget.innerHTML
    } else if (this.hasContentValue) {
      options.content = this.contentValue
    }
    this.tippy = tippy(this.element, options)
  }

  disconnect () {
    this.tippy.destroy()
  }
}
