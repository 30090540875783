import {
  AutoForm,
  NestedForm,
  ScrollLock,
  Sortable
} from '@mguidetti/stimulus-components'
import { Toggle } from 'tailwindcss-stimulus-components'
import { application } from './application'
import AutoFormCombobox from './auto_form_combobox_controller'
import Attachments from './attachments_controller'
import Checkbox from './checkbox_controller'
import Click from './click_controller'
import Details from './details_controller'
import Dropdown from './dropdown_controller'
import HwComboboxController from "@josefarias/hotwire_combobox"
import LocalStorage from './local_storage_controller'
import OrderedAssociations from './ordered_associations_controller'
import Select from './select_controller'
import TextareaAutosize from './textarea_autosize_controller'
import Theme from './theme_controller'
import TipTap from './tiptap_controller'
import Toast from './toast_controller'
import Tooltip from './tooltip_controller'

application.register('auto-form', AutoForm)
application.register('auto-form-combobox', AutoFormCombobox)
application.register('attachments', Attachments)
application.register('checkbox', Checkbox)
application.register('click', Click)
application.register('details', Details)
application.register('dropdown', Dropdown)
application.register("hw-combobox", HwComboboxController)
application.register('local-storage', LocalStorage)
application.register('nested-form', NestedForm)
application.register('ordered-associations', OrderedAssociations)
application.register('scroll-lock', ScrollLock)
application.register('select', Select)
application.register('sortable', Sortable)
application.register('textarea-autosize', TextareaAutosize)
application.register('theme', Theme)
application.register('tiptap', TipTap)
application.register('toast', Toast)
application.register('toggle', Toggle)
application.register('tooltip', Tooltip)
